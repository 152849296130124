import React, { useState } from "react";
import { LOCALSTORGAE_LABELS, GPS, isProductionSite } from "../constants";
import "./styles.css";

export default function SubmitReport() {
  const [imageURI] = useState<string>(localStorage[LOCALSTORGAE_LABELS.CAPTURED_IMAGE]);
  const [gps] = useState<GPS>(JSON.parse(localStorage[LOCALSTORGAE_LABELS.CAPTURED_POSITION]));
  const [emailAddress, emailAddressSet] = useState("");
  const [isUploading, isUploadingSet] = useState<boolean | null>(null);

  return (
    <div className="container-fluid page-submit-report">
      <header>
        <h1 className="display-1 text-center">Confirm Report</h1>
        <hr />
      </header>

      <article>
        {imageURI && (
          <div className="camera-image">
            <img className="camera-image" src={imageURI} alt="" />
          </div>
        )}

        <div>
          <hr />
          <h5>Location details</h5>
          <div className="container">
            <div className="row">
              <div className="col-3">Longitude</div>
              <div className="col-9">{gps.longitude}</div>
            </div>
            <div className="row">
              <div className="col-3">Latitude</div>
              <div className="col-9">{gps.latitude}</div>
            </div>
          </div>
          <hr />
          <h5>Contact details</h5>
          <div className="container">
            <div className="row">
              <div className="col-3">Email</div>
              <div className="col-9">
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Optional"
                  value={emailAddress}
                  onChange={(e) => emailAddressSet(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </article>

      <footer className="fixed-bottom-none">
        <hr />
        <div className="flex space-between">
          <button className="btn btn-sm btn-warning" onClick={gotoHome} disabled={!!isUploading}>
            Cancel
          </button>
          <button className="btn btn-lg btn-success" onClick={sendData} disabled={!!isUploading}>
            {!!isUploading ? "Uploading" : "Submit"}
          </button>
        </div>
      </footer>
    </div>
  );

  function gotoHome() {
    window.location.pathname = "/";
  }
  function gotoThankYou() {
    window.location.pathname = "/thank-you";
  }
  function sendData() {
    const timestamp = new Date().valueOf();
    const email = emailAddress ? emailAddress.replaceAll("@", "(at)") : "";
    const fileType = ("" + imageURI).split(";")[0].split("/")[1];
    const filename = `${timestamp}-${email}-${gps.latitude}-${gps.longitude}-${nowString()}.${fileType}`;
    const file = dataURLtoFile(imageURI, filename);

    const formData = new FormData();
    formData.append("userfile", file);

    let URL = `https://eco-patrol-api.sunderwood.co.uk/uploaded/${filename}`;
    if (isProductionSite) {
      URL = `https://eco-patrol-api.teensandfriendsunited.org/uploaded/${filename}`;
    }

    const method = "POST";
    const request = new XMLHttpRequest();
    request.addEventListener("progress", updateProgress);
    request.addEventListener("load", transferComplete);
    request.addEventListener("error", transferFailed);
    request.addEventListener("abort", transferCanceled);
    request.open(method, URL);
    request.send(formData);
    isUploadingSet(true);
  }
  function updateProgress() {
    console.log("[XMLHttpRequest] progress...");
  }
  function transferComplete() {
    isUploadingSet(false);
    gotoThankYou();
  }
  function transferFailed() {
    isUploadingSet(false);
    alert("☹️ that Failed!\nTry Submitting again, or try a little later, or contact eco-patrol@teensandfriendsunited.org");
  }
  function transferCanceled() {
    isUploadingSet(false);
  }
}

function nowString() {
  return new Date().toISOString().substr(0, 10);
}

function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(","),
    // @ts-ignore
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  // @ts-ignore
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
