import React from "react";

export function RedirectIfTrue(props: { value: boolean; path: string }) {
  if (!!props.value) {
    window.location.pathname = props.path;
  }
  return <></>;
}

export type AUTHORISATION = "true" | "false";
export const AUTHORISED = "true";
export const UNAUTHORISED = "false";
