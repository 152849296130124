import React from "react";
import "./styles.css";

export default function SubmitReport() {

  return (
    <div className="container-fluid page-thank-you">
      <article className="flex center-items">
        <h4 className="text-center">Your report has been sent!</h4>
      </article>

      <header>
        <h1 className="display-1 text-center">Thank You!</h1>
      </header>

      <footer className="fixed-bottom-none">
        <hr />
        <div className="flex space-between">
          <button className="btn btn-sm btn-warning" onClick={gotoHome}>
            Back to the Start
          </button>
        </div>
      </footer>
    </div>
  );

  function gotoHome() {
    window.location.pathname = "/";
  }
}
