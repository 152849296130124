import React from "react";

import Home from "./screens/Home";
import SetupCamera from "./screens/SetupCamera";
import SetupGPS from "./screens/SetupGPS";
import UseCamera from "./screens/MakeReport";
import SubmitReport from "./screens/SubmitReport";
import ThankYou from "./screens/ThankYou";

import "./App.css";

const routes: any = {
  "/": () => <Home />,
  "/setup-camera": () => <SetupCamera />,
  "/setup-gps": () => <SetupGPS />,
  "/use-camera": () => <UseCamera />,
  "/submit-report": () => <SubmitReport />,
  "/thank-you": () => <ThankYou />,
};

const NotFoundPage = () => <p>Not Found :( <a href="/">Go Home</a></p>;

function useRoutes (routes: any) {
  const route = window.location.pathname;
  const result = routes[route] || null;
  console.log(`[useRoutes]: route=${route}`);
  return result;
}

function App() {
  console.log('[App] render');
  const routeResult = useRoutes(routes);
  return routeResult ?  routeResult() : <NotFoundPage />;
}

export default App;

