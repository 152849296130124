import React from "react";
import headerImage from "../../images/eco-patrol-banner1.png";
import "./styles.css";

export default function Home() {
  return (
    <div className="container-fluid page-home">
      <div data-page="1" className="page">
        <div className="logo">
          <a href="https://teensandfriendsunited.org/"><img src={headerImage} alt="logo" /></a>
        </div>

        <div className="content">
          {/* <h1 className="display-1 text-center">TAFU Eco-Patrol 🌍</h1> */}
          <hr />
          <p className="">Report any rubbish and we'll try to find someone to clean it up!</p>
          <ol>
            <li>Click the Report Rubbish button</li>
            <li>Take a picture</li>
            <li>Log the location</li>
            <li>Send your Rubbish Report to Eco-Patrol</li>
          </ol>
        </div>
      </div>

      <footer className="fixed-bottom-none">
        <hr />
        <div className="flex space-around">
          <button className="btn btn-lg btn-success" onClick={gotoSetupCamera}>
            Send Rubbish Report
          </button>
        </div>
      </footer>
    </div>
  );
  function gotoSetupCamera() {
    window.location.pathname = "/setup-camera";
  }
}
