import React, { useEffect, useState } from "react";
import Camera, { CameraProps } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { GPS, LOCALSTORGAE_LABELS, CameraType } from "../constants";
import { usePosition } from "../usePosition";
import "./styles.css";

export default function MakeReport() {
  const [imageURI, imageURISet] = useState("");
  const [position, positionSet] = useState<GPS>({ longitude: undefined, latitude: undefined });
  const [facingMode, facingModeSet] = useState<CameraType>("user");
  const { latitude, longitude, getPermission } = usePosition(true);
  const isActiveGPS = !latitude;
  
  console.log({position});

  useEffect(() => {
    getPermission();
  }, [getPermission]);

  const cameraProps: CameraProps = {
    imageType: "jpg",
    idealFacingMode: facingMode,
    isMaxResolution: false,
    onTakePhoto: (dataUri: string) => [imageURISet(dataUri), positionSet({ longitude, latitude })],
    onCameraError: (error: any) => console.log("error has occurred", JSON.stringify(error)),
    onCameraStart: () => console.log("camera started"),
    onCameraStop: () => console.log("camera stopped"),
  };

  return (
    <div className="container-fluid page-use-camera">
      <header>
        <hr />
      </header>

      <article>
        <div className="camera-display-holder">
          {imageURI && (
            <div className="camera-image">
              <img className="camera-image" src={imageURI} alt="" />
            </div>
          )}
          {!imageURI && facingMode === "user" && <Camera {...cameraProps} />}
          {!imageURI && facingMode === "environment" && <Camera {...cameraProps} />}
        </div>

        {!imageURI && (
          <div className="camera-controls-holder">
            <button className="btn btn-lg btn-warning" onClick={swapCameraTypes}>
              Swap Cam
            </button>
          </div>
        )}

        {imageURI && (
          <div className="camera-controls-holder">
            <button className="btn btn-lg btn-danger" onClick={clearImage}>
              Again
            </button>
            {!latitude ? (
              <button className="btn btn-lg btn-success" disabled>
                Waiting for GPS
              </button>
            ) : (
              <button className="btn btn-lg btn-success" onClick={gotoSubmitReport}>
                Picture Good?
              </button>
            )}
          </div>
        )}

        <ul className="">
          <li>Latitude: {isActiveGPS ? "Waiting for GPS..." : latitude}</li>
          <li>Longitude: {isActiveGPS ? "Waiting for GPS..." : longitude}</li>
        </ul>
      </article>

      {/* <footer className="fixed-bottom-none">
        <hr />
        <div className="">
          <button className="btn btn-sm btn-warning" onClick={gotoHome}>
            Cancel
          </button>
        </div>
      </footer> */}
    </div>
  );

  function swapCameraTypes() {
    if (facingMode === "environment") {
      facingModeSet("user");
    } else {
      facingModeSet("environment");
    }
  }
  function clearImage() {
    imageURISet("");
  }
  function gotoSubmitReport() {
    localStorage[LOCALSTORGAE_LABELS.CAPTURED_IMAGE] = imageURI;
    localStorage[LOCALSTORGAE_LABELS.CAPTURED_POSITION] = JSON.stringify(position);
    window.location.pathname = "/submit-report";
  }
}
