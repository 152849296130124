import React, { useState } from "react";
import Camera from "react-html5-camera-photo";

import { RedirectIfTrue, UNAUTHORISED, AUTHORISED, AUTHORISATION } from "../utils";
import "react-html5-camera-photo/build/css/index.css";
import "./styles.css";

const AUTHORISATION_LABEL = "camera-authorised";

export default function SetupCamera() {
  const [attachedCamera, attachedCameraSet] = useState(false);
  const [imageURI] = useState("");
  const previouslyAuthorised = getAuthorised();

  return (
    <div className="container-fluid page-camera-setup">
      <RedirectIfTrue value={previouslyAuthorised} path="/setup-gps" />
      <header>
        <h1 className="display-1 text-center">Setup Camera</h1>
        <hr />
      </header>

      <article>
        <div className="camera-display-holder">
          {imageURI && (
            <div className="camera-image">
              <img className="camera-image" src={imageURI} alt="" />
            </div>
          )}
          {!imageURI && !attachedCamera && (
            <>
              <div className="camera-image">
                <div className="camera-image"></div>
              </div>
              <h4 className="text-center">Please Authorise the use of your camera</h4>
            </>
          )}
          {!imageURI && attachedCamera && (
            <>
              <Camera
                // onTakePhoto={(dataUri) => {
                //   imageURISet(dataUri);
                // }}
                idealFacingMode="environment"
                imageType="jpg"
                isMaxResolution={true}
                onCameraError={(error) => {
                  console.log("error has occurred", JSON.stringify(error));
                  setAuthorised(UNAUTHORISED);
                }}
                onCameraStart={() => cameraAuthorised()}
                onCameraStop={() => console.log("camera stopped")}
              />
            </>
          )}
        </div>
      </article>

      <footer className="fixed-bottom-none">
        <hr />
        <div className="flex space-around">
          <button className="btn btn-lg btn-success" onClick={setupCamera}>
            Authorise Camera
          </button>
        </div>
      </footer>
    </div>
  );

  function setupCamera() {
    attachedCameraSet(true);
  }

  function getAuthorised(): boolean {
    return localStorage[AUTHORISATION_LABEL] === AUTHORISED;
  }

  function setAuthorised(value: AUTHORISATION): void {
    localStorage[AUTHORISATION_LABEL] = value;
  }

  function cameraAuthorised(){
    setAuthorised(AUTHORISED);
    window.location.pathname = "/setup-gps";
  }
}
