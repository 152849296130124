export const LOCALSTORGAE_LABELS = {
  CAPTURED_IMAGE: "CAPTURED_IMAGE",
  CAPTURED_POSITION: "CAPTURED_POSITION",
};

export interface GPS {
  longitude: undefined | number | string,
  latitude: undefined | number | string,
}

export const NO_POSITION: GPS = {
  longitude: "Not Available",
  latitude: "Not Available",
};

export type CameraType = "environment" | "user";

export const isProductionSite = window.location.href.indexOf("teensandfriendsunited") > 0;