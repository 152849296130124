import React from "react";
import { usePosition } from "../usePosition";
import "./styles.css";
import { RedirectIfTrue } from "../utils";

export default function SetupGPS() {
  const { latitude, error, isSearching, getPermission, previouslyAuthorised } = usePosition(true);
  const isActiveGPS = previouslyAuthorised || (!isSearching && !error && latitude);
  const isInactiveGPS = !previouslyAuthorised && !error && !latitude;

  return (
    <div className="container-fluid page-setup-gps">
      <RedirectIfTrue value={previouslyAuthorised} path="/use-camera" />
      <header>
        <h1 className="display-1 text-center">Setup GPS</h1>
        <hr />
      </header>

      <article>
        <p className="text-center">Allow us to use your GPS to automatically collect the location of the rubbish you find.</p>
        {isActiveGPS && <h2 className="text-center">GPS is Active!</h2>}
        {isInactiveGPS && <h2 className="text-center">GPS is not active!</h2>}
        {isSearching && (
          <>
            <h2 className="text-center">Connecting to GPS</h2>
            {/* <div className="bird-container bird-container--one">
              <div className="bird bird--one"></div>
            </div> */}
          </>
        )}
      </article>

      <footer className="fixed-bottom-none">
        <hr />
        <div className="flex space-around">
          {isInactiveGPS && (
            <button className="btn btn-lg btn-success" disabled={isSearching} onClick={() => authoriseGPS()}>
              {!isSearching && "Authorise GPS"}
              {isSearching && "Authorising GPS"}
            </button>
          )}
          {isActiveGPS && (
            <button className="btn btn-lg btn-success" onClick={useCamera}>
              To Rubbish
            </button>
          )}
        </div>
      </footer>
    </div>
  );
  function useCamera() {
    window.location.pathname = "/use-camera";
  }
  function authoriseGPS() {
    getPermission();
  }
}
