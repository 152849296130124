import { useState } from "react";

import { UNAUTHORISED, AUTHORISED, AUTHORISATION } from "./utils";

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};
type GeolocationErrorNotSupported = "Geolocation is not supported";
const GEOLOCATIOON_NOT_SUPPORTED = "Geolocation is not supported";
type GeolocationPositionErrorTypes = GeolocationPositionError | null | GeolocationErrorNotSupported;
const AUTHORISATION_LABEL = "gps-authorised";
interface usePositionData extends GeolocationCoordinates {
  error?: GeolocationPositionErrorTypes;
}

export function usePosition(debug = false) {
  const [position, setPosition] = useState<usePositionData | {}>({});
  const [error, setError] = useState<GeolocationPositionErrorTypes | any>(null);
  const [isSearching, isSearchingSet] = useState<boolean>(false);
  const previouslyAuthorised = getAuthorised();
  console.log(`[usePosition] debug=${debug}`);

  return { ...position, error, isSearching, getPermission, previouslyAuthorised };

  function getAuthorised(): boolean {
    return localStorage[AUTHORISATION_LABEL] === AUTHORISED;
  }

  function setAuthorised(value: AUTHORISATION): void {
    localStorage[AUTHORISATION_LABEL] = value;
  }

  function onChange({ coords: { latitude, longitude }, timestamp }: GeolocationPosition) {
    console.log(`[usePosition] onChange:`, { coords: { latitude, longitude }, timestamp });
    setPosition({ latitude, longitude, timestamp });
    setAuthorised(AUTHORISED);
    isSearchingSet(false);
  }

  function onError(error: GeolocationPositionError) {
    console.log(`[usePosition] onError:`, { error });
    setError(error.message);
    isSearchingSet(false);
    setAuthorised(UNAUTHORISED);
  }

  async function getPermission() {
    isSearchingSet(true);
    if (!navigator || !navigator.geolocation) {
      setError(GEOLOCATIOON_NOT_SUPPORTED);
      isSearchingSet(false);
      setAuthorised(UNAUTHORISED);
      debug && console.log("getPermission.", GEOLOCATIOON_NOT_SUPPORTED);
      return;
    }
    debug && console.log("getPermission.", "getting settings");
    isSearchingSet(true);
    navigator.geolocation.getCurrentPosition(onChange, onError, defaultSettings);
  }
}
